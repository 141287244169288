import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@wui/basics/typography';
import Button from '@wui/input/button';
import Spacer from '@wui/layout/spacer';
import Box from '@material-ui/core/Box';
import { DigexIntroProgressPlaceholder } from '@c/progress';

import WuiApp from 'material-ui/WuiApp';
import Layout from '@c/layout/Layout';
import MuiWizardPage from 'material-ui/components/layout/MuiWizardPage';
import { NotarizationWizardPageMixin } from 'ui/components/wizard/NotarizationWizardPageMixin';
import { NotarizationPrepLocation, NotarizationStatus } from 'utils/enums';

const styles = theme => ({
  header: {
    marginBottom: 40,
    '& h2': {
      display: 'inline-flex',
      alignItems: 'center',
      marginBottom: 0,
      '&:before': {
        content: '""',
        borderRadius: [[2, 0, 0, 2]],
        display: 'block',
        width: 8,
        height: 32,
        backgroundColor: theme.palette.primary.main,
        marginRight: 20,
      },
    },
  },
  text: {
    '& + &': {
      marginTop: 28, // Body line height
    },
  },
  digexProgressContainer: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
});

class DigexPrepSigningProcedure extends NotarizationWizardPageMixin(MuiWizardPage) {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };
  static baseUrl = 'prep/sign-proc/';
  static notarizationSection = NotarizationStatus.PREP;

  constructor(...args) {
    super(...args);

    this.state = {
      includesSpouse: false,
    };
  }

  handleSession = session => {
    this.setState({
      includesSpouse: session.includesSpouse,
    });
  };

  renderActionButtons = () => (
    <React.Fragment>
      <Button color="primary" variant="contained" onClick={() => this.goToNext()}>
        Continue
      </Button>
    </React.Fragment>
  );

  render() {
    const { classes } = this.props;
    return (
      <WuiApp wrapContent={false}>
        <Layout
          wide
          hideRight
          digexPrepPage={NotarizationPrepLocation.SIGN_PROC}
          useDigexProgress
          showBackButton
          appLocation={NotarizationPrepLocation.SIGN_PROC}
          buttonRenderer={this.renderActionButtons}
        >
          <Box className={classes.header}>
            <Typography variant="h2">2. The Signing Procedure</Typography>
          </Box>
          <Typography variant="body1" className={classes.text}>
            The application of your {this.state.includesSpouse ? 'signatures' : 'signature'} will be
            done differently than you may be accustomed to. Some documents require specific words on
            the recording, and these statements will be shown at the appropriate time. Some
            documents require a witness to be present, which will be provided by MetLife Legal Plans
            for your convenience.
          </Typography>
          <Typography variant="body1" className={classes.text}>
            The notary's job is to walk you through the signing process to ensure that everything is
            done properly, and they will instruct you when to act.
            {this.state.includesSpouse &&
              ' The notary will assist the first spouse with their documents and then assist the other.'}
          </Typography>
          <Spacer v={40} />
          <Box className={classes.digexProgressContainer}>
            <DigexIntroProgressPlaceholder />
          </Box>
        </Layout>
      </WuiApp>
    );
  }
}

export default withStyles(styles)(DigexPrepSigningProcedure);
