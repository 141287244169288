import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@wui/layout/grid';
import Typography from '@wui/basics/typography';
import Button from '@wui/input/button';
import { handleChange } from '@willing-shared/utils/input';
import Spacer from '@wui/layout/spacer';
import Box from '@material-ui/core/Box';
import { DigexIntroProgressPlaceholder } from '@c/progress';

import WuiApp from 'material-ui/WuiApp';
import Layout from '@c/layout/Layout';
import MuiWizardPage from 'material-ui/components/layout/MuiWizardPage';
import { NotarizationWizardPageMixin } from 'ui/components/wizard/NotarizationWizardPageMixin';
import { NotarizationPrepLocation, NotarizationStatus } from 'utils/enums';

const styles = theme => ({
  header: {
    marginBottom: 40,
    '& h2': {
      display: 'inline-flex',
      alignItems: 'center',
      marginBottom: 20,
      '&:before': {
        content: '""',
        borderRadius: [[2, 0, 0, 2]],
        display: 'block',
        width: 8,
        height: 32,
        backgroundColor: theme.palette.primary.main,
        marginRight: 20,
      },
    },
  },
  digexProgressContainer: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
  checkboxItem: {
    display: 'flex',
    alignItems: 'start',
    '& p': {
      marginTop: 6,
      marginLeft: 7, // Makes space between built in checkbox padding and text 16
    },
  },
  listItem: {
    '& + &': {
      marginTop: 4,
    },
  },
  iconContainer: {
    padding: [[0, 16, 0, 0]],
  },
  icon: {
    height: 28, // Line height of body1 text
    display: 'flex',
    alignItems: 'center',
    '& span': {
      backgroundColor: theme.palette.primary.main,
    },
  },
});

const ListItem = ({ text, classes }) => (
  <Grid container direction="row" wrap="nowrap" className={classes.listItem}>
    <Grid item className={classes.iconContainer}>
      <Box className={classes.icon}>
        <Spacer h={8} v={8} />
      </Box>
    </Grid>
    <Grid container item>
      <Typography variant="body1">{text}</Typography>
    </Grid>
  </Grid>
);

ListItem.propTypes = {
  text: PropTypes.string.isRequired,
  classes: PropTypes.object,
};

ListItem.defaultProps = {
  classes: {},
};

const StyledListItem = withStyles(styles)(ListItem);

class DigexPrepReviewReqs extends NotarizationWizardPageMixin(MuiWizardPage) {
  constructor(...args) {
    super(...args);

    this.state = {
      recordPermission: false,
      acknowledgeRequirements: false,
      includesSpouse: false,
    };

    this.handleChange = handleChange.bind(this);
  }
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };
  static baseUrl = 'prep/review-reqs/';
  static notarizationSection = NotarizationStatus.PREP;

  handleSession = session => {
    this.setState({
      includesSpouse: session.includesSpouse,
    });
  };

  renderActionButtons = () => {
    const { recordPermission, acknowledgeRequirements } = this.state;
    return (
      <React.Fragment>
        <Button
          color="primary"
          variant="contained"
          disabled={!(acknowledgeRequirements && recordPermission)}
          onClick={() => this.goToNext()}
        >
          Continue
        </Button>
      </React.Fragment>
    );
  };

  render() {
    const { classes } = this.props;
    const { recordPermission, acknowledgeRequirements } = this.state;
    return (
      <WuiApp wrapContent={false}>
        <Layout
          wide
          hideRight
          digexPrepPage={NotarizationPrepLocation.REVIEW_REQS}
          useDigexProgress
          showBackButton
          appLocation={NotarizationPrepLocation.REVIEW_REQS}
          buttonRenderer={this.renderActionButtons}
        >
          <Box className={classes.header}>
            <Typography variant="h2">1. Review and Acknowledge Requirements</Typography>
            <Typography variant="body1">
              Remote notarization is a formal process and must conform to a strict set of standards
              established by law. If you feel that you cannot conform to these standards, you are
              still free to download your estate planning documents and execute them physically with
              a live notary and witnesses.
            </Typography>
          </Box>
          <StyledListItem text="By law, remote notary sessions must be recorded, and the recording will be stored with your electronic documents as evidence of the signing." />
          <Spacer v={24} />
          <Box className={classes.checkboxItem}>
            <Checkbox
              color="primary"
              checked={recordPermission}
              name="recordPermission"
              onChange={this.handleChange}
            />
            <Typography variant="body1">
              I grant MetLife Legal Plans permission to record the remote notary session.
            </Typography>
          </Box>
          <Spacer v={24} />
          <StyledListItem text="There cannot be any interruptions." />
          {this.state.includesSpouse && (
            <StyledListItem text="If you prepared documents for both yourself and your spouse, both of you must be present to sign with the notary. You cannot have anyone other than your spouse with you in the room over the age of 18." />
          )}
          {!this.state.includesSpouse && (
            <StyledListItem text="You cannot have anyone with you in the room over the age of 18." />
          )}
          <StyledListItem text="The remote notary must be confident that you are not distracted or under duress." />
          <StyledListItem text="You must remain in the frame of the camera for the full session and your audio-video connection must be clear." />
          <StyledListItem text="If the notary is uncomfortable with your ability to complete the remote notary session for any reason, the session will be cancelled with the option to reschedule (if applicable) or to have you physically execute your documents." />
          <Spacer v={24} />
          <Box className={classes.checkboxItem}>
            <Checkbox
              color="primary"
              checked={acknowledgeRequirements}
              name="acknowledgeRequirements"
              onChange={this.handleChange}
            />
            <Typography variant="body1">
              I acknowledge that I understand the preceding requirements and accept that the notary
              may terminate the session if they feel that the requirements for a remote notarization
              are not being met.
            </Typography>
          </Box>
          <Spacer v={40} />
          <Box className={classes.digexProgressContainer}>
            <DigexIntroProgressPlaceholder />
          </Box>
        </Layout>
      </WuiApp>
    );
  }
}

export default withStyles(styles)(DigexPrepReviewReqs);
