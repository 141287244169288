import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { TunnelPlaceholder, TunnelProvider } from 'react-tunnels';
import { ThemeProvider as MuiThemeProvider, makeStyles } from '@material-ui/core/styles';

import '@willing-shared/assets/stylesheets/normalize.scss';
import { ProgressPlaceholder, WuiProgress } from '@c/progress';
import { disableStandardStyles, enableStandardStyles } from '@u/dom';
import Theme from '@willing-shared/components/Theme';
import WuiTheme from '@willing-shared/WuiTheme';

import CssBaseline from '@material-ui/core/CssBaseline';
import Header from '@willing-shared/components/layout/Header';
import HeaderHeightProvider from '@willing-shared/components/HeaderHeightProvider';
import Navigation from '@c/layout/Header/Navigation';

import { useGlobalContext, useScreenSize } from 'hooks';
import Urls from 'urls';

const useStyles = makeStyles({
  impersonating: {},

  appBar: {
    '&$impersonating': {
      background: 'rgba(255, 0, 0, 0.3)',
    },
  },
});

const App = ({ showFooter, showHeader, wrapContent, children, ...headerProps }) => {
  const classes = useStyles();
  const { clientDataHolder, serverData } = useGlobalContext();
  const { isLaptopOrSmallerDisplay } = useScreenSize();

  useEffect(() => {
    disableStandardStyles();

    return () => {
      enableStandardStyles();
    };
  });

  const Wrapper = wrapContent ? 'div' : React.Fragment;
  const headerClassName = serverData.impersonating ? classes.impersonating : null;
  const forDigex = clientDataHolder.props.location.pathname.includes(Urls.notarizePrep);

  return (
    <TunnelProvider>
      <MuiThemeProvider theme={Theme}>
        <CssBaseline />
        <WuiTheme>
          <WuiProgress />
        </WuiTheme>
        <Wrapper>
          <HeaderHeightProvider>
            {showHeader && (
              <Header
                className={headerClassName}
                navigation={
                  <Navigation
                    fromPortunus={serverData.fromPortunus}
                    fromUpwise={clientDataHolder.clientData.covidEmployer === 'upwise'}
                    isFreeLook={serverData.isFreeLook}
                  />
                }
                classes={{ appBar: classes.appBar }}
                fromPortunus={serverData.fromPortunus}
                fromUpwise={clientDataHolder.clientData.covidEmployer === 'upwise'}
                {...headerProps}
              >
                {isLaptopOrSmallerDisplay && <ProgressPlaceholder forDigex={forDigex} />}

                <TunnelPlaceholder id="testator-switcher" />
              </Header>
            )}
            <WuiTheme>{children}</WuiTheme>
          </HeaderHeightProvider>
        </Wrapper>
      </MuiThemeProvider>
    </TunnelProvider>
  );
};

App.propTypes = {
  showFooter: PropTypes.bool,
  showHeader: PropTypes.bool,
  wrapContent: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

App.defaultProps = {
  showFooter: true,
  showHeader: true,
  wrapContent: false,
};

export default observer(App);
