import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Sticky } from 'react-sticky';
import { withStyles } from '@material-ui/core/styles';
import { isWidthDown } from '@material-ui/core/withWidth';

import { Relationship } from 'models/client-data/enums';
import {
  Progress,
  ProgressSections,
  WuiProgress,
  WuiProgressPlaceholder,
  DigexIntroProgressBar,
  DigexIntroProgress,
} from '@c/progress';

import WuiTheme from '@willing-shared/WuiTheme';
import Spacer from '@wui/layout/spacer';
import BackButton from '@c/layout/BackButton';
import TabDivider from '@wui/layout/tabDivider';
import SectionTabs from '@wui/layout/sectionTabs';
import FormHelperText from '@material-ui/core/FormHelperText';
import TipsAndSummarySidebar from '@c/TipsAndSummarySidebar';
import StyledButton from '@willing-shared/components/StyledButton';
import { digitalEstatePlanningService } from '@willing-shared/utils/env';
import withIsMobileDisplay from '@willing-shared/hocs/withIsMobileDisplay';
import TestatorSwitcherTunnel from '@c/layout/Header/TestatorSwitcherTunnel';
import TwoColumnContent from '@willing-shared/components/layout/TwoColumnContent';
import TwoColumnContentLeft from '@willing-shared/components/layout/TwoColumnContentLeft';

import FocusTrap from 'focus-trap-react';

const styles = theme => ({
  buttonContainer: {
    display: 'flex',
    marginTop: 16,
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  withPointerEvents: {
    pointerEvents: [['auto'], '!important'],
  },
  disabled: {
    pointerEvents: [['none'], '!important'],
  },
  centered: {
    textAlign: 'center',
  },
});

class Layout extends React.Component {
  static propTypes = {
    top: PropTypes.node,
    width: PropTypes.string.isRequired,
    buttonRenderer: PropTypes.func,
    secondaryButtonProps: PropTypes.shape({}),
    progressPage: PropTypes.string,
    testatorSwitcher: PropTypes.bool,
    progressSection: PropTypes.shape({}),
    appLocation: PropTypes.string,
    nextButtonText: PropTypes.string,
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    nextValidatesWhenDisabled: PropTypes.bool,
    wide: PropTypes.oneOf([false, true, 1, 2]),
    showPlanHealth: PropTypes.bool,
    theme: PropTypes.shape({}),
    hideRight: PropTypes.bool,
    showBackButton: PropTypes.bool,
    backButtonProps: PropTypes.shape({}),
    digexPrepPage: PropTypes.string,
    useDigexProgress: PropTypes.bool,
  };

  static contextTypes = {
    page: PropTypes.object,
    history: PropTypes.object,
    section: PropTypes.object,
    testator: PropTypes.object,
    experiments: PropTypes.object,
    headerHeight: PropTypes.object,
    clientDataHolder: PropTypes.object,
  };

  static defaultProps = {
    top: null,
    buttonRenderer: null,
    secondaryButtonProps: null,
    nextButtonText: 'Next',
    testatorSwitcher: false,
    nextValidatesWhenDisabled: true,
    showPlanHealth: false,
    hideRight: false,
    showBackButton: false,
    backButtonProps: {},
    useDigexProgress: false,
  };

  get testator() {
    return this.context.testator;
  }

  get clientData() {
    return this.context.clientDataHolder.clientData;
  }

  get navigationTabs() {
    const spouseUrl = this.generateTabUrl(true);
    const {
      section: { canVisitPath },
    } = this.context;

    return [
      {
        name: this.clientData.firstName,
        url: this.generateTabUrl(false),
      },
      {
        url: spouseUrl,
        disabled: !canVisitPath(spouseUrl),
        name: this.clientData.spouse.firstName,
      },
    ];
  }

  get selectedTabIndex() {
    return Number(this.testator.clientRelationship === Relationship.SPOUSE);
  }

  onClick(controlWithError) {
    // If a previous click triggered a save that is in progress,
    //   don't do anything because we need that to finish first.
    if (this.context.section.nextPending) {
      return;
    }

    if (!controlWithError) {
      this.context.page.goToNext();
    } else {
      controlWithError.focusOnError();
    }
  }

  selectTab = tabIndex => {
    const { history } = this.context;

    if (tabIndex === this.selectedTabIndex) {
      return;
    }

    history.push(this.navigationTabs[tabIndex].url);
  };

  generateTabUrl(forSpouse) {
    const {
      section: { url },
      page: { generateUrl },
    } = this.context;

    return `${url}${generateUrl(forSpouse)}`;
  }

  renderLayoutTop() {
    const { testatorSwitcher, showBackButton, backButtonProps } = this.props;

    if (!testatorSwitcher || !this.clientData.isPlanningForSpouse) {
      return showBackButton ? (
        <WuiTheme>
          <BackButton showRightLine={false} {...backButtonProps} />
        </WuiTheme>
      ) : null;
    }

    const {
      headerHeight: { value: headerHeight },
    } = this.context;

    const navigation = this.isPhoneDisplay ? (
      this.renderStickyNavigation()
    ) : (
      <Sticky topOffset={-headerHeight}>{this.renderStickyNavigation}</Sticky>
    );

    return <TestatorSwitcherTunnel>{navigation}</TestatorSwitcherTunnel>;
  }

  renderStickyNavigation = ({ isSticky = false, style = {} } = {}) => {
    const {
      headerHeight: { value: headerHeight },
    } = this.context;
    const { wide, theme, showBackButton, backButtonProps } = this.props;

    const stickyStyle = {
      position: 'relative',
      ...style,
      marginTop: isSticky ? headerHeight : 0,
      zIndex: 1000,
      backgroundColor: theme.palette.common.white,
    };

    return (
      <div style={stickyStyle}>
        <WuiTheme>
          {showBackButton && <BackButton {...backButtonProps} />}
          <TwoColumnContentLeft wide={wide} padded={false}>
            <SectionTabs
              onSelect={this.selectTab}
              tabs={this.navigationTabs}
              selected={this.selectedTabIndex}
            />
          </TwoColumnContentLeft>

          <TabDivider noMargin />
        </WuiTheme>
      </div>
    );
  };

  renderRight() {
    const { hideRight } = this.props;

    if (hideRight) {
      return null;
    }

    return (
      <WuiTheme>
        <WuiProgressPlaceholder />
        <Spacer v={24} />
        <TipsAndSummarySidebar />
      </WuiTheme>
    );
  }

  renderButton = externalButtonProps => {
    // If Layout is rendered in a component that does not have section
    //   props, then we don't want a button at all.
    if (!this.context.section) {
      return null;
    }

    const { isLoading } = this.context.clientDataHolder;
    const { nextPending } = this.context.section;
    const saving = nextPending || isLoading;

    const {
      buttonRenderer,
      classes,
      nextButtonText,
      nextValidatesWhenDisabled,
      secondaryButtonProps,
    } = this.props;

    const controlWithError = this.context.page.firstControlWithError();
    const buttonText = saving ? 'Saving...' : nextButtonText;
    const allValid = !controlWithError;

    const buttonProps = externalButtonProps || {
      type: 'submit',
      component: 'button',
      color: 'primary',
      'data-path': 'next',
      children: buttonText,
      variant: 'contained',
      disabled: !allValid || saving,
      onClick: this.onClick.bind(this, controlWithError),
      className: nextValidatesWhenDisabled && classes.withPointerEvents,
      noBottomSpacer: Boolean(secondaryButtonProps),
      size: 'medium',
    };

    return buttonRenderer ? (
      buttonRenderer(buttonProps, { nextPending: saving })
    ) : (
      <StyledButton {...buttonProps} />
    );
  };

  render() {
    // `wide` is not destructured so that it is included
    //   in `other` and passed along.
    const {
      children,
      classes,
      progressPage,
      progressSection,
      top,
      appLocation,
      secondaryButtonProps,
      digexPrepPage,
      useDigexProgress,
      ...other
    } = this.props;
    const showPlanHealth =
      this.props.showPlanHealth &&
      !this.clientData.covidEmployer &&
      !this.serverData.isFreeLook &&
      !digitalEstatePlanningService;

    const className = classNames(classes.buttonContainer, {
      [classes.centered]: this.props.wide,
    });

    const childrenClass =
      this.context.section && this.context.section.nextPending ? classes.disabled : '';

    const { isLoading } = this.context.clientDataHolder;
    const isLaptopOrSmallerDisplay = isWidthDown('md', this.props.width);

    // The button uses an `a` component so that the `disabled`
    //   property is not passed along to the rendered component.
    //   This allows us to show the disabled styles, but accept
    //   clicks.
    // We always allow pointer events for the button as well so
    //   that it is clickable, even when visually disabled.
    return (
      <React.Fragment>
        <TwoColumnContent
          top={this.renderLayoutTop()}
          right={this.renderRight()}
          {...other}
          wuiNavLayout={true}
        >
          <FocusTrap paused={!isLoading}>
            <div className={childrenClass}>
              <div tabIndex={0} />
              {children}
            </div>
          </FocusTrap>

          {this.context.section && this.context.section.postErrorMessage && (
            <FormHelperText error={true}>{this.context.section.postErrorMessage}</FormHelperText>
          )}

          <div className={className}>
            {this.renderButton()}
            {secondaryButtonProps && (
              <>
                <Spacer h={16} />
                {this.renderButton(secondaryButtonProps)}
              </>
            )}
          </div>
        </TwoColumnContent>

        {progressPage && (
          <WuiTheme>
            <Progress page={progressPage} section={progressSection || ProgressSections.ABOUT_YOU} />
          </WuiTheme>
        )}

        {digexPrepPage && useDigexProgress && (
          <WuiTheme>
            <DigexIntroProgressBar page={digexPrepPage} />
          </WuiTheme>
        )}

        {/* This needs to be after the ProgressPlaceholder
                    rendered by the right side in order to render
                    properly. */}

        {!isLaptopOrSmallerDisplay && useDigexProgress && (
          <DigexIntroProgress appLocation={appLocation} />
        )}
        <WuiProgress appLocation={appLocation} showPlanHealth={showPlanHealth} />
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withIsMobileDisplay(Layout));
