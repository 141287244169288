import React from 'react';
import PropTypes from 'prop-types';
import { Tunnel } from 'react-tunnels';
import { withStyles } from '@material-ui/core/styles';
import withIsMobileDisplay from '@willing-shared/hocs/withIsMobileDisplay';

import { NotarizationPrepLocation } from 'utils/enums';

const styles = theme => ({
  root: {
    width: '100%',
    flexShrink: 0,
    display: 'flex',
    margin: [[0, 'auto']],
    flexDirection: 'column',

    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%',
      marginBottom: 48,
    },
  },
  bar: {
    height: 5,
    borderRadius: 2,
    overflow: 'hidden',
    background: theme.palette.custom.border,

    '$root$expanded &': {
      margin: [[8, 0]],
    },

    [theme.breakpoints.up('lg')]: {
      margin: [[8, 0]],
    },
  },
  barFill: {
    height: '100%',
    background: theme.gradients.special,
  },
});

export const DigexIntroProgressPages = [
  NotarizationPrepLocation.REVIEW_REQS,
  NotarizationPrepLocation.SIGN_PROC,
  NotarizationPrepLocation.ABOUT_NOTARY,
  NotarizationPrepLocation.DOC_REVIEW,
];

class DigexIntroProgressBar extends React.Component {
  static propTypes = {
    page: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
  };

  renderBar() {
    const { classes, page } = this.props;

    // The bar never shows as completely empty,
    //   and never shows as completely full.
    //   This is the total padding on each side
    //   that will not be available for actual
    //   status.
    const unusedPercent = 12;

    const completePages = DigexIntroProgressPages.findIndex(e => e === page);
    const percentPerPage = (100 - unusedPercent) / DigexIntroProgressPages.length.toFixed(2);
    const percentComplete = unusedPercent / 2 + completePages * percentPerPage;

    return (
      <div className={classes.bar} role="progressbar" aria-valuenow={Math.trunc(percentComplete)}>
        <div className={classes.barFill} style={{ width: `${percentComplete}%` }} />
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Tunnel id="digex-intro-progress-bar">
        <div className={classes.root}>{this.renderBar()}</div>
      </Tunnel>
    );
  }
}

export default withStyles(styles)(withIsMobileDisplay(DigexIntroProgressBar));
