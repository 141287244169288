import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tunnel } from 'react-tunnels';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@wui/layout/grid';
import Panel from '@wui/layout/panel';
import Spacer from '@wui/layout/spacer';
import CustomIcon from '@wui/basics/customIcon';
import Typography from '@wui/basics/typography';

import { NotarizationPrepLocation } from 'utils/enums';
import { locationInfo } from 'utils/locations';
import withIsMobileDisplay from '@willing-shared/hocs/withIsMobileDisplay';

import { ReactComponent as PendingIcon } from '@a/images/pending.svg';
import { ReactComponent as GreenTickIcon } from '@a/images/green-tick.svg';
import SectionNotarize from 'ui/sections/notarize/SectionNotarize';
import DigexPrepReviewReqs from 'ui/sections/notarize/pages/prep/DigexPrepReviewReqs';
import DigexPrepSignProcedure from 'ui/sections/notarize/pages/prep/DigexPrepSigningProcedure';
import DigexPrepAboutNotary from 'ui/sections/notarize/pages/prep/DigexPrepAboutNotary';
import DigexPrepDocReview from 'ui/sections/notarize/pages/prep/DigexPrepDocReview';

const styles = theme => ({
  panel: {
    '& [class*="Panel-specialIndicator"][class*="Panel-alternateIndicator"]': {
      borderRadius: [[2, 0, 0, 2]],
    },
  },
  item: {
    cursor: 'default',
  },
  clickable: {
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.background.default,
    },
  },
  active: {
    margin: '-1px !important',
    zIndex: theme.zIndex.above,
  },
  // Largely ripped off of Bootstrap5
  visuallyHidden: {
    position: ['absolute', '!important'],
    width: ['1px', '!important'],
    height: ['1px', '!important'],
    padding: ['0px', '!important'],
    margin: ['-1px', '!important'],
    overflow: ['hidden', '!important'],
    clip: ['rect(0,0,0,0)', '!important'],
    whiteSpace: ['nowrap', '!important'],
    border: [['0'], '!important'],
  },
});

class DigexIntroProgressPlaceholder extends React.Component {
  static contextTypes = {
    clientDataHolder: PropTypes.object,
  };

  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    appLocation: PropTypes.string,
  };

  static defaultProps = {
    appLocation: null,
  };

  handleClick = (id, url, clickable) => () => {
    if (id === this.props.appLocation) {
      return;
    }

    if (clickable) {
      this.context.clientDataHolder.props.history.push(url);
    }
  };

  renderItem = item => {
    const { serverData } = this.context.clientDataHolder;
    const { classes, appLocation } = this.props;
    const { id, index, completed, clickable } = item;
    let { url, name } = locationInfo(serverData)[id];

    const active = id === appLocation;

    let iconProps = {
      src: completed ? GreenTickIcon : PendingIcon,
      height: 16,
      width: 16,
    };

    if (active) {
      if (completed) {
        iconProps.src = GreenTickIcon;
      }
    }

    const itemClass = classNames(classes.item, {
      [classes.clickable]: clickable,
      [classes.active]: active,
    });

    const ariaLabel = active
      ? 'Current page'
      : `${completed ? 'Completed' : 'In Progress'} section`;

    return (
      <Panel
        key={url}
        paddingless
        noMargin
        special={active}
        indicatorVariant="alternate"
        onClick={this.handleClick(id, url, clickable)}
        borderless={!active}
        className={itemClass}
        indicatorMovesContent={false}
      >
        <Spacer v={3} />
        <Grid container direction="row" alignItems="center">
          <Spacer h={18} />
          <CustomIcon {...iconProps} />
          <Spacer h={18} />
          <Typography color="#525252" role="link">
            <span className={classes.visuallyHidden}>{ariaLabel}</span>
            <span>
              {active ? (
                <strong>
                  {index}. {name}
                </strong>
              ) : (
                `${index}. ${name}`
              )}
            </span>
          </Typography>
          <Spacer h={40} />
        </Grid>
        <Spacer v={3} />
      </Panel>
    );
  };

  render() {
    const { classes } = this.props;
    const { clientData } = this.context.clientDataHolder;
    const items = [
      {
        id: NotarizationPrepLocation.REVIEW_REQS,
        index: 1,
        completed: clientData.isPageValid(SectionNotarize, DigexPrepReviewReqs),
        clickable: true,
      },
      {
        id: NotarizationPrepLocation.SIGN_PROC,
        index: 2,
        completed: clientData.isPageValid(SectionNotarize, DigexPrepSignProcedure),
        clickable: clientData.isPageValid(SectionNotarize, DigexPrepReviewReqs),
      },
      {
        id: NotarizationPrepLocation.ABOUT_NOTARY,
        index: 3,
        completed: clientData.isPageValid(SectionNotarize, DigexPrepAboutNotary),
        clickable: clientData.isPageValid(SectionNotarize, DigexPrepSignProcedure),
      },
      {
        id: NotarizationPrepLocation.DOC_REVIEW,
        index: 4,
        completed: clientData.isPageValid(SectionNotarize, DigexPrepDocReview),
        clickable: clientData.isPageValid(SectionNotarize, DigexPrepAboutNotary),
      },
    ];

    return (
      <Tunnel id="digex-intro-progress">
        <Panel paddingless className={classes.panel}>
          <Spacer v={14} />
          {items.map(this.renderItem)}
          <Spacer v={14} />
        </Panel>
      </Tunnel>
    );
  }
}

export default withStyles(styles)(withIsMobileDisplay(DigexIntroProgressPlaceholder));
