import React from 'react';
import PropTypes from 'prop-types';

import FieldGroup from '../FieldGroup';
import { runTypeValidation } from '!m/utils';
import { Asset } from '!m/client-data/assets';
import BoundTextField from '@c/BoundTextField';
import BoundRadioGroup from '@c/BoundRadioGroup';
import ListItemSideTitle from '../ListItemSideTitle';
import { getStateFullName } from 'utils';
import { AssetOwnership, AssetType } from '!m/client-data/enums';
import AddressInput from 'material-ui/components/AddressInput';

export default class Property extends React.Component {
  static propTypes = {
    asset: PropTypes.object,
    disabled: PropTypes.bool,
  };

  static contextTypes = {
    testator: PropTypes.object,
    experiments: PropTypes.object,
    clientDataHolder: PropTypes.object,
  };

  newAsset = new Asset({ type: AssetType.REAL_ESTATE });

  // If an asset is supplied, then we should modify that.
  //   Otherwise, we modify an asset that is not actually
  //   stored for future use.
  get asset() {
    return this.props.asset || this.newAsset;
  }

  remove() {
    this.context.testator.removeAssetByInstance(this.asset);
  }

  get sideTitle() {
    return (
      <ListItemSideTitle
        item={this.asset}
        onDelete={this.remove.bind(this)}
        list={this.context.testator.realEstates}
      />
    );
  }

  realEstateMustBeOwnedByBoth(state = null) {
    return Asset.realEstateMustBeOwnedByBoth(state || this.asset.address.state);
  }

  ownershipOptions(state) {
    return Asset.availableOwnershipTypes(this.context.testator, state || this.asset.address.state);
  }

  setOwners() {
    this.context.clientDataHolder.updateClientData(() => {
      this.asset.setOwnersBasedOnOwnershipType(this.context.testator);
    });
  }

  // Ensure that the ownership type that was previously selected
  //   is still available. If not, clear it so the user can
  //   choose a proper value.
  checkOwnershipType = (oldState, newState) => {
    const state = newState || this.asset.address.state;

    if (!Object.keys(this.ownershipOptions(state)).includes(this.asset.ownershipType)) {
      this.context.clientDataHolder.updateClientData(() => {
        this.asset.clearOwnership(this.context.testator);
      });
    }
  };

  get ownershipOptionsHelperText() {
    const hide =
      this.asset.ownershipType ||
      !this.realEstateMustBeOwnedByBoth() ||
      !this.context.testator.isMarried;

    if (hide) {
      return null;
    }

    const state = getStateFullName(this.asset.address.state);
    return `In ${state}, both you and your spouse must be owners of this property.`;
  }

  renderOwnershipOptions() {
    const validations = [() => runTypeValidation(AssetOwnership, this.asset.ownershipType || '')];

    return (
      <BoundTextField
        raw={false}
        label="Owned By"
        obj={this.asset}
        path="ownershipType"
        skipStandardValidation={true}
        options={this.ownershipOptions()}
        disabled={!this.asset.hasAddress}
        additionalValidations={validations}
        onChange={this.setOwners.bind(this)}
        helperText={this.ownershipOptionsHelperText}
      />
    );
  }

  get enablePrimaryResidence() {
    return this.asset.hasAddress && this.asset.ownershipType;
  }

  // If the user sets the asset to be the primary
  //   residence, then all other real estate assets
  //   should not be the primary residence.
  clearOtherPrimaries() {
    if (!this.asset.isPrimaryResidence) {
      return;
    }

    this.context.clientDataHolder.updateClientData(() => {
      this.context.testator.realEstates.forEach(a => {
        if (a === this.asset) {
          return;
        }

        a.setRawValue('isPrimaryResidence', false);
      });
    });
  }

  render() {
    const title = this.asset.address.street || 'Add Your Property';

    return (
      <FieldGroup
        level={2}
        variant="h4"
        title={title}
        sideTitle={this.sideTitle}
        disabled={this.props.disabled}
      >
        <AddressInput hideTitle obj={this.asset} onStateChange={this.checkOwnershipType} />

        {this.renderOwnershipOptions()}

        <BoundRadioGroup
          obj={this.asset}
          path="isPrimaryResidence"
          disabled={!this.enablePrimaryResidence}
          label="Is this your primary residence?"
          onChange={this.clearOtherPrimaries.bind(this)}
        />
      </FieldGroup>
    );
  }
}
