import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@wui/layout/grid';
import Typography from '@wui/basics/typography';
import Button from '@wui/input/button';
import Spacer from '@wui/layout/spacer';
import Box from '@material-ui/core/Box';

import WuiApp from 'material-ui/WuiApp';
import Layout from '@c/layout/Layout';
import MuiWizardPage from 'material-ui/components/layout/MuiWizardPage';
import { NotarizationWizardPageMixin } from 'ui/components/wizard/NotarizationWizardPageMixin';
import { NotarizationStatus } from 'utils/enums';
import { getDigexScheduleLink } from 'utils/constants';

const styles = theme => ({
  listItem: {
    '& + &': {
      marginTop: 20,
    },
  },
  iconContainer: {
    padding: [[0, 16, 0, 0]],
  },
  icon: {
    height: 28, // Line height of body1 text
    display: 'flex',
    alignItems: 'center',
    '& span': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  header: {
    marginBottom: 74,
  },
});

const ListItem = ({ title, text, classes, titleVariant, bodyVariant }) => (
  <Grid container direction="row" wrap="nowrap" className={classes.listItem}>
    <Grid item className={classes.iconContainer}>
      <Box className={classes.icon}>
        <Spacer h={8} v={8} />
      </Box>
    </Grid>
    <Grid container item direction="column">
      <Typography variant={titleVariant}>{title}</Typography>
      <Typography variant={bodyVariant}>{text}</Typography>
    </Grid>
  </Grid>
);

ListItem.propTypes = {
  title: PropTypes.string.isRequired,
  titleVariant: PropTypes.string,
  bodyVariant: PropTypes.string,
  text: PropTypes.string.isRequired,
  classes: PropTypes.object,
};

ListItem.defaultProps = {
  titleVariant: 'h6',
  bodyVariant: 'body1',
  classes: {},
};

const StyledListItem = withStyles(styles)(ListItem);

class DigexPrepIntro extends NotarizationWizardPageMixin(MuiWizardPage) {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };
  static baseUrl = 'prep/';
  static notarizationSection = NotarizationStatus.PREP;

  constructor(...args) {
    super(...args);
    this.state = {
      includesSpouse: false,
    };
  }

  handleSession = session => {
    this.setState({
      includesSpouse: session.includesSpouse,
    });
  };

  renderActionButtons = () => (
    <React.Fragment>
      <Button color="primary" variant="contained" onClick={() => this.goToNext()}>
        I'm ready to start now
      </Button>
      <Spacer h={16} v={16} />
      {!this.serverData.isFreeLook && (
        <Button
          variant="outlined"
          onClick={() => window.location.replace(getDigexScheduleLink(this.serverData))}
        >
          Schedule for later
        </Button>
      )}
    </React.Fragment>
  );

  render() {
    const { classes } = this.props;
    return (
      <WuiApp wrapContent={false}>
        <Layout wide hideRight buttonRenderer={this.renderActionButtons}>
          <Box className={classes.header}>
            <Typography variant="h2" className={classes.header}>
              Before We Start
            </Typography>
          </Box>
          <StyledListItem
            title="Be prepared to sign all documents"
            text="Please review all your documents in advance and be ready to sign everything that has been created."
          />
          <StyledListItem
            title="Set aside at least 30 minutes"
            text="Please ensure that you are uninterrupted during the time needed to complete the notary process. Also please note, you will be required to be in the video frame throughout the call. "
          />
          <StyledListItem
            title="Have valid identification ready"
            text="You will be required to verify your identity. You will need to upload a copy of your government-issued I.D., which must include your photo and signature. Examples of acceptable I.D. are state-issued I.D., driver's licenses, and U.S. passports."
          />
          {this.state.includesSpouse && (
            <StyledListItem
              title="Make sure your spouse is present in the room"
              text="If you will be signing documents for both you and your spouse, it is important that they are present during this digital notarization session."
            />
          )}
          <StyledListItem
            title="Ensure no one else is present"
            text="Only those who are signing the documents are allowed to be present in the room. The video call will be conducted in English."
          />
          <Spacer v={84} />
        </Layout>
      </WuiApp>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DigexPrepIntro);
