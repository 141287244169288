export const OPENTOK_API_KEY = '47770961';
export const MEMBERS_PHONE_NUMBER = '(800) 821-6400';
const DIGEX_CALENDLY_LINK =
  'https://outlook.office365.com/owa/calendar/MetlifeDigitalEstatePlanningNotaryCalendar@legalplans.com/bookings/';
const FREE_LOOK_CALENDLY_LINK =
  'https://outlook.office365.com/book/MetLifeLegalPlansPromotionalCalendar@legalplans.com/';
export const getDigexScheduleLink = serverData =>
  serverData.isFreeLook ? FREE_LOOK_CALENDLY_LINK : DIGEX_CALENDLY_LINK;

export const DEFAULT_DOCUMENT_TITLE = 'Online Wills & Estate Planning';
