import { AppLocation, NotarizationPrepLocation } from 'utils/enums';
import Urls from 'urls';

export const locationInfo = serverData => ({
  [AppLocation.HOME]: {
    url: Urls.planDashboard,
    name: 'Home',
  },
  [AppLocation.ACCOUNT]: {
    url: Urls.account,
    name: 'Account',
    parent: AppLocation.HOME,
  },
  [AppLocation.ABOUT_YOU]: {
    url: Urls.sectionAboutYou,
    name: 'Basic Info',
  },
  [AppLocation.FAMILY]: {
    url: Urls.family,
    name: 'Family',
  },
  [AppLocation.REAL_ESTATE]: {
    url: Urls.realEstate,
    name: 'Real Estate',
  },
  [AppLocation.GUARDIANS]: {
    url: Urls.sectionGuardians,
    name: 'Guardians',
  },
  [AppLocation.ASSET_BENEFICIARIES]: {
    url: Urls.sectionAssetBeneficiaries,
    name: 'Assets',
  },
  [AppLocation.EXECUTORS]: {
    url: Urls.sectionExecutors,
    name: 'Executors',
  },
  [AppLocation.HEALTHCARE]: {
    url: Urls.sectionHealthcare,
    name: 'Healthcare',
  },
  [AppLocation.DOCUMENTS]: {
    url: serverData.documentBundle ? Urls.signSection : Urls.documents + Urls.reviewBase,
    name: 'Documents',
  },
  [AppLocation.FAMILY_CENTER]: {
    url: Urls.sectionNotify,
    name: 'Family Center',
  },
  [NotarizationPrepLocation.REVIEW_REQS]: {
    url: Urls.notarizeReviewReqs,
    name: 'Requirements',
  },
  [NotarizationPrepLocation.SIGN_PROC]: {
    url: Urls.notarizeSignProc,
    name: 'Signing Procedure',
  },
  [NotarizationPrepLocation.ABOUT_NOTARY]: {
    url: Urls.notarizeAboutNotary,
    name: 'Your Notary',
  },
  [NotarizationPrepLocation.DOC_REVIEW]: {
    url: Urls.notarizeDocReview,
    name: 'Document Review',
  },
});

const anonymousLocationInfo = locationInfo({});

export function getRootLocation(location) {
  while (anonymousLocationInfo[location].parent) {
    location = anonymousLocationInfo[location].parent;
  }
  return location;
}

export function getLocationPath(location) {
  const originalLocation = location;
  const result = [];

  while (anonymousLocationInfo[location].parent) {
    result.push(location);
    location = anonymousLocationInfo[location].parent;
    if (location === originalLocation) {
      throw Error('Cycle in location parents');
    }
  }
  result.push(location);

  return result.reverse().map(id => anonymousLocationInfo[id]);
}
