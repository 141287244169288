import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@wui/basics/typography';
import Button from '@wui/input/button';
import Spacer from '@wui/layout/spacer';
import Box from '@material-ui/core/Box';
import { DigexIntroProgressPlaceholder } from '@c/progress';

import WuiApp from 'material-ui/WuiApp';
import Layout from '@c/layout/Layout';
import MuiWizardPage from 'material-ui/components/layout/MuiWizardPage';
import { NotarizationWizardPageMixin } from 'ui/components/wizard/NotarizationWizardPageMixin';
import { NotarizationPrepLocation, NotarizationStatus } from 'utils/enums';

const styles = theme => ({
  header: {
    marginBottom: 40,
    '& h2': {
      display: 'inline-flex',
      alignItems: 'center',
      marginBottom: 0,
      '&:before': {
        content: '""',
        borderRadius: [[2, 0, 0, 2]],
        display: 'block',
        width: 8,
        height: 32,
        backgroundColor: theme.palette.primary.main,
        marginRight: 20,
      },
    },
  },
  text: {
    '& + &': {
      marginTop: 28, // Body line height
    },
  },
  digexProgressContainer: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
});

class DigexPrepAboutNotary extends NotarizationWizardPageMixin(MuiWizardPage) {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };
  static baseUrl = 'prep/about-notary/';
  static notarizationSection = NotarizationStatus.PREP;

  renderActionButtons = () => (
    <React.Fragment>
      <Button color="primary" variant="contained" onClick={() => this.goToNext()}>
        Continue
      </Button>
    </React.Fragment>
  );

  render() {
    const { classes } = this.props;
    return (
      <WuiApp wrapContent={false}>
        <Layout
          wide
          hideRight
          digexPrepPage={NotarizationPrepLocation.ABOUT_NOTARY}
          useDigexProgress
          showBackButton
          appLocation={NotarizationPrepLocation.ABOUT_NOTARY}
          buttonRenderer={this.renderActionButtons}
        >
          <Box className={classes.header}>
            <Typography variant="h2">3. About Your Notary</Typography>
          </Box>
          <Typography variant="body1" className={classes.text}>
            The remote notary and any witnesses are physically located in Nevada and your documents
            will be executed according to Nevada remote notary laws. The interpretation and legal
            validity of your estate planning documents are still governed by the state in which you
            live.
          </Typography>
          <Typography variant="body1" className={classes.text}>
            Neither the notary nor any witness is an attorney and cannot answer any questions you
            may have about interpreting the contents of your estate planning documents. The notary
            may answer questions regarding the signing procedure, but any legal or off-topic
            questions could be grounds for terminating the session.
          </Typography>
          <Typography variant="body1" className={classes.text}>
            By choosing to continue, you acknowledge that the notary cannot answer any legal
            questions about your estate planning documents.
          </Typography>
          <Spacer v={40} />
          <Box className={classes.digexProgressContainer}>
            <DigexIntroProgressPlaceholder />
          </Box>
        </Layout>
      </WuiApp>
    );
  }
}

export default withStyles(styles)(DigexPrepAboutNotary);
