import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import Urls from '!/urls';
import WuiTheme from '@willing-shared/WuiTheme';
import Grid from '@wui/layout/grid';
import AccountNav from './AccountNav';
import WuiLink from '@wui/basics/link';
import Typography from '@wui/basics/typography';
import VerticalDivider from '@wui/layout/verticalDivider';
import MobileNavMenu from '@c/layout/Header/MobileNavMenu';
import WuiPhoneLink from 'material-ui/components/WuiPhoneLink';
import HeaderLink from '@willing-shared/components/layout/HeaderLink';
import { digitalEstatePlanningService } from '@willing-shared/utils/env';
import withIsMobileDisplay from '@willing-shared/hocs/withIsMobileDisplay';
import Button from '@wui/input/button';

const styles = {
  menuButton: {
    padding: 8,

    '&:focus-visible': {
      fallbacks: [
        // https://css-tricks.com/copy-the-browsers-native-focus-styles/
        { outline: '5px auto Highlight' },
        { outline: '5px auto -webkit-focus-ring-color' },
      ],
    },
  },
  badge: {
    top: 3,
    left: -14,
    width: 18,
    minWidth: 18,
    height: 18,
    fontWeight: 'bold',
  },
  guideButton: {
    paddingTop: [6, '!important'],
    paddingBottom: [6, '!important'],
    marginRight: 16,
  },
  returnButton: {
    paddingTop: [6, '!important'],
    paddingBottom: [6, '!important'],
    right: 46,
  },
  menuIcon: {
    fontSize: 32,
  },
  headerRight: {
    position: 'relative',
    height: '100%',

    '& > *': {
      height: '100%',
    },
  },
  accountNav: {
    position: ['absolute', '!important'],
    top: 'calc(100% - 8px)',
    right: 0,
    width: 400,
  },
  accountBackdrop: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
};

class Navigation extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    fromPortunus: PropTypes.bool,
    fromUpwise: PropTypes.bool,
    isFreeLook: PropTypes.bool,
  };

  static contextTypes = {
    clientDataHolder: PropTypes.object,
    experiments: PropTypes.object,
    history: PropTypes.object.isRequired,
  };

  static defaultProps = {
    fromPortunus: true,
    fromUpwise: false,
    isFreeLook: false,
  };

  state = {
    menuOpen: false,
    invisible: false,
    menuAnchor: null,
    accountOpen: false,
  };

  get isLoggedIn() {
    return Boolean(this.context.clientDataHolder.clientData.email);
  }

  logout = () => {
    this.context.clientDataHolder.logout();
  };

  changeMenuState = state => () => {
    this.setState({ menuOpen: state, invisible: true });
  };

  openMenu = event => {
    this.setState({ menuAnchor: event.currentTarget });
  };

  closeMenu = () => {
    this.setState({ menuAnchor: null });
  };

  openAccountNav = () => this.setState({ accountOpen: true });

  goToLogin = () => {
    const { history } = this.context;
    const { fromPortunus } = this.props;
    if (fromPortunus) {
      window.location = window.WillingConfig.membersAppLink;
      return;
    }
    history.push(Urls.login);
  };

  closeAccountNav = () => this.setState({ accountOpen: false });

  onClickHandler = e => {
    const { history } = this.context;
    const { fromPortunus, fromUpwise } = this.props;
    e.preventDefault();
    if (fromUpwise) {
      window.location = '#close';
    } else if (fromPortunus) {
      window.location = `${window.WillingConfig.membersAppLink}/services`;
    } else {
      history.push(Urls.planDashboard);
    }
  };

  renderReturnButton() {
    const { classes, fromPortunus, fromUpwise, isFreeLook } = this.props;

    if (!fromPortunus || isFreeLook || digitalEstatePlanningService) {
      return <></>;
    }

    const buttonText = fromUpwise ? 'Return to Upwise' : 'Return to main menu';

    return (
      <Hidden smDown>
        <Button
          variant="outlined"
          size="small"
          transparent
          noMinWidth
          className={classes.returnButton}
          onClick={this.onClickHandler}
        >
          {buttonText}
        </Button>
      </Hidden>
    );
  }

  renderPhoneOrPermissions() {
    const { permissions } = this.context.clientDataHolder;

    if (permissions.length === 1) {
      return <WuiPhoneLink />;
    }

    const { menuAnchor } = this.state;
    const open = Boolean(menuAnchor);

    return (
      <React.Fragment>
        <HeaderLink href="#" title="Choose Role" onClick={this.openMenu}>
          Choose Role
        </HeaderLink>
        <Menu open={open} anchorEl={menuAnchor} onClose={this.closeMenu}>
          {permissions.map(([link, role]) => (
            <MenuItem to={link} key={role} component={Link} onClick={this.closeMenu}>
              {role}
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    );
  }

  renderSubscriptionPlan = () => {
    const { documentBundle, subscriptionPlan } = this.context.clientDataHolder.serverData;

    if (!documentBundle || !subscriptionPlan) {
      return '';
    }
    return (
      <HeaderLink title="Subscription Plans" to={Urls.subscriptionPicker}>
        Subscription Plans
      </HeaderLink>
    );
  };

  renderAccountLink = () => (
    <WuiLink
      component="button"
      underline="none"
      className="open-account-nav"
      onClick={this.isLoggedIn ? this.openAccountNav : this.goToLogin}
      role="menu"
      aria-expanded={this.state.accountOpen}
      data-path="accountNavDesktop"
    >
      <Grid container direction="row">
        <Typography align="left" variant="body2">
          {this.isLoggedIn
            ? this.context.clientDataHolder.clientData.name || 'My Account'
            : 'Log In'}
        </Typography>
        {this.state.accountOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Grid>
    </WuiLink>
  );

  render() {
    const { classes } = this.props;
    const { accountOpen, menuOpen } = this.state;

    const mobileMenuVisible = menuOpen && this.isLaptopOrSmallerDisplay;
    const showGuide = menuOpen === 'guide';

    return (
      <WuiTheme>
        <Hidden mdDown implementation="css" className={classes.headerRight}>
          <Grid container direction="row" wrap="nowrap" alignItems="center">
            {this.renderReturnButton()}
            {this.renderPhoneOrPermissions()}
            <VerticalDivider h={32} />
            {this.renderAccountLink()}
            {accountOpen && (
              <React.Fragment>
                <div className={classes.accountBackdrop} onClick={this.closeAccountNav} />
                <AccountNav tooltip className={classes.accountNav} close={this.closeAccountNav} />
              </React.Fragment>
            )}
          </Grid>
        </Hidden>
        <Hidden lgUp implementation="css">
          {this.isLoggedIn ? (
            <>
              {this.renderReturnButton()}
              <Hidden only="xs">
                <Button
                  variant="outlined"
                  size="small"
                  transparent
                  noMinWidth
                  className={classes.guideButton}
                  onClick={this.changeMenuState('guide')}
                >
                  Guide
                </Button>
              </Hidden>
              <IconButton
                className={classNames(classes.menuButton, 'hamburger-button')}
                onClick={this.changeMenuState(this.isPhoneDisplay ? 'guide' : 'account')}
                data-path="accountNavMobile"
              >
                <MenuIcon className={classes.menuIcon} />
              </IconButton>
              <MobileNavMenu
                onClose={this.changeMenuState(false)}
                open={mobileMenuVisible}
                showGuide={showGuide}
              />
            </>
          ) : (
            <>
              {this.renderReturnButton()}
              this.renderAccountLink()
            </>
          )}
        </Hidden>
      </WuiTheme>
    );
  }
}

export default withStyles(styles)(withIsMobileDisplay(Navigation));
