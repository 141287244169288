import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@wui/basics/typography';
import Button from '@wui/input/button';
import Spacer from '@wui/layout/spacer';
import Box from '@material-ui/core/Box';
import { DigexIntroProgressPlaceholder } from '@c/progress';
import { digitalEstatePlanningService } from '@willing-shared/utils/env';
import Loading from '@willing-shared/components/loading';
import GenericError from '@wui/basics/genericError';

import { API } from 'API';
import WuiApp from 'material-ui/WuiApp';
import Layout from '@c/layout/Layout';
import MuiWizardPage from 'material-ui/components/layout/MuiWizardPage';
import { NotarizationWizardPageMixin } from 'ui/components/wizard/NotarizationWizardPageMixin';
import { NotarizationPrepLocation, NotarizationStatus } from 'utils/enums';
import { base64ToBlob } from 'utils';

const styles = theme => ({
  header: {
    marginBottom: 40,
    '& h2': {
      display: 'inline-flex',
      alignItems: 'center',
      marginBottom: 0,
      '&:before': {
        content: '""',
        borderRadius: [[2, 0, 0, 2]],
        display: 'block',
        width: 8,
        height: 32,
        backgroundColor: theme.palette.primary.main,
        marginRight: 20,
      },
    },
  },
  text: {
    '& + &': {
      marginTop: 28, // Body line height
    },
  },
  digexProgressContainer: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
  docViewerContainer: {
    width: '100%',
    height: 0,
    paddingBottom: '141%',
    position: 'relative',
    '& embed': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
});

class DigexPrepDocReview extends NotarizationWizardPageMixin(MuiWizardPage) {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };
  static baseUrl = 'prep/doc-review/';
  static notarizationSection = NotarizationStatus.PREP;

  completePrep = () => {
    API.postNotarizationPrepComplete().then(this.goToNext());
  };

  constructor(...args) {
    super(...args);

    this._isMounted = false;
    this.state = {
      error: false,
      errorMessage: null,
      loading: false,
      docUrl: null,
      includesSpouse: false,
    };
  }

  handleSession = session => {
    this.setState({
      includesSpouse: session.includesSpouse,
    });
  };

  async componentDidMount() {
    this._isMounted = true;

    try {
      this.setState({ loading: true });
      API.getDocumentsPdf({ digitalEstatePlanningService, generateDigexDocs: true }).then(
        response => {
          this.setState({ loading: false });
          if (!this._isMounted) {
            return;
          }
          const blob = base64ToBlob(response.data);
          const objectUrl = URL.createObjectURL(blob);
          this.setState({ docUrl: objectUrl });
        },
      );
    } catch (error) {
      this.setState({
        loading: false,
        error: true,
        errorMessage: error.message,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  renderActionButtons = () => (
    <React.Fragment>
      <Button color="primary" variant="contained" onClick={() => this.completePrep()}>
        Continue
      </Button>
    </React.Fragment>
  );

  render() {
    const { classes } = this.props;
    const { error, errorMessage, loading, docUrl } = this.state;
    return (
      <WuiApp wrapContent={false}>
        <Layout
          wide
          hideRight
          digexPrepPage={NotarizationPrepLocation.DOC_REVIEW}
          useDigexProgress
          showBackButton
          appLocation={NotarizationPrepLocation.DOC_REVIEW}
          buttonRenderer={this.renderActionButtons}
        >
          <Box className={classes.header}>
            <Typography variant="h2">4. Finally, Reviewing Your Documents.</Typography>
          </Box>
          <Typography variant="body1" className={classes.text}>
            Please take the opportunity now to review your documents for accuracy.
          </Typography>
          <Typography variant="body1" className={classes.text}>
            You will find in your documents the "Certificate of Qualified Custodian". This document
            is required by law and has been signed by the general counsel of MetLife Legal Plans. It
            simply states that MetLife Legal Plans agrees to store your electronically signed
            documents on your behalf.
          </Typography>
          <Spacer v={40} />
          {error && (
            <Box className={classes.error}>
              <GenericError message={errorMessage} />
            </Box>
          )}
          {loading && <Loading>Loading your documents...</Loading>}
          {!loading && docUrl && (
            <Box className={classes.docViewerContainer}>
              <embed type="application/pdf" src={docUrl} width="100%" height="auto" />
            </Box>
          )}
          <Spacer v={40} />
          <Typography variant="body1" className={classes.text}>
            {!this.state.includesSpouse &&
              'It is recommended that you review your estate planning documents to determine if you need to ask an attorney any legal questions before signing your documents. '}
            {this.state.includesSpouse &&
              'It is recommended that both you and your spouse review your estate planning documents now to determine if either of you need to ask an attorney any legal questions before signing your documents. '}
            By choosing to continue, you affirm that you have reviewed your estate planning
            documents.
          </Typography>
          <Spacer v={40} />
          <Box className={classes.digexProgressContainer}>
            <DigexIntroProgressPlaceholder />
          </Box>
        </Layout>
      </WuiApp>
    );
  }
}

export default withStyles(styles)(DigexPrepDocReview);
