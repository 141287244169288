import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@wui/basics/typography';
import Button from '@wui/input/button';
import Spacer from '@wui/layout/spacer';
import Box from '@material-ui/core/Box';
import { DigexIntroProgressPlaceholder } from '@c/progress';

import WuiApp from 'material-ui/WuiApp';
import Layout from '@c/layout/Layout';
import MuiWizardPage from 'material-ui/components/layout/MuiWizardPage';
import { NotarizationWizardPageMixin } from 'ui/components/wizard/NotarizationWizardPageMixin';
import { NotarizationStatus } from 'utils/enums';

const styles = theme => ({
  exampleProgress: {
    marginTop: 29,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
    display: 'flex',
    pointerEvents: 'none',
  },
});

class DigexPrepStart extends NotarizationWizardPageMixin(MuiWizardPage) {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };
  static baseUrl = 'prep/start/';
  static notarizationSection = NotarizationStatus.PREP;

  renderActionButtons = () => (
    <React.Fragment>
      <Button color="primary" variant="contained" onClick={() => this.goToNext()}>
        Continue
      </Button>
    </React.Fragment>
  );

  render() {
    const { classes } = this.props;
    return (
      <WuiApp wrapContent={false}>
        <Layout
          wide
          hideRight
          showBackButton
          useDigexProgress
          buttonRenderer={this.renderActionButtons}
        >
          <Box>
            <Typography variant="h2">Preparing to Sign and Notarize</Typography>
            <Typography variant="body1">
              Before connecting you to a live notary, there are a few steps to go through to ensure
              a successful signing experience. We estimate less than 10 minutes to complete these
              steps.
            </Typography>
          </Box>
          <Box className={classes.exampleProgress}>
            <DigexIntroProgressPlaceholder />
          </Box>
          <Spacer v={29} />
        </Layout>
      </WuiApp>
    );
  }
}

export default withStyles(styles)(DigexPrepStart);
