import React from 'react';
import PropTypes from 'prop-types';
import { TunnelPlaceholder } from 'react-tunnels';

export default class ProgressPlaceholder extends React.Component {
  static propTypes = {
    forDigex: PropTypes.bool,
  };

  static defaultProps = {
    forDigex: false,
  };
  render() {
    const { forDigex } = this.props;
    if (forDigex) {
      return <TunnelPlaceholder id="digex-intro-progress-bar" />;
    }
    return <TunnelPlaceholder id="progress" />;
  }
}
