export class DocumentBundle {
  static SINGLE = 'single';
  static COUPLES = 'married';
  static HOMEOWNERS = 'homeowner';
  static ULTIMATE = 'ultimate';

  static displayName = bundle =>
    ({
      [DocumentBundle.ULTIMATE]: 'Ultimate Care',
      [DocumentBundle.HOMEOWNERS]: 'Homeowners',
      [DocumentBundle.COUPLES]: 'Married Couple',
      [DocumentBundle.SINGLE]: 'Individual',
    }[bundle]);

  static sixMonths = '6 months';
  static threeYears = '3 years';
  static changePeriod = bundle =>
    ({
      [DocumentBundle.ULTIMATE]: DocumentBundle.threeYears,
      [DocumentBundle.HOMEOWNERS]: DocumentBundle.sixMonths,
      [DocumentBundle.COUPLES]: DocumentBundle.sixMonths,
      [DocumentBundle.SINGLE]: DocumentBundle.sixMonths,
    }[bundle]);
}

export class SubscriptionPlan {
  static PRO = 'pro';
  static PLUS = 'plus';
  static PEACE = 'peace';
  static PEACE_NOTRIAL = 'peace_notrial';
  static FOR_SINGLES = 'for_singles';
  static FOR_COUPLES = 'for_couples';
  static PEACE_PLANS = [
    SubscriptionPlan.PEACE,
    SubscriptionPlan.PEACE_NOTRIAL,
    SubscriptionPlan.FOR_SINGLES,
    SubscriptionPlan.FOR_COUPLES,
  ];

  static displayName(subscriptionPlan) {
    if (SubscriptionPlan.PEACE_PLANS.includes(subscriptionPlan)) {
      return 'Peace of Mind Plan';
    }

    return 'Subscription Plan';
  }
}

export class DocumentType {
  static WILL = 'will';
  static ADVANCE_HEALTHCARE_DIRECTIVE = 'advance_healthcare_directive';
  static POWER_OF_ATTORNEY = 'power_of_attorney';
  static TRUST = 'trust';
  static DEED = 'deed';

  static displayName(documentType) {
    switch (documentType) {
      case DocumentType.DEED:
        return 'Transfer on Death Deed';
      case DocumentType.ADVANCE_HEALTHCARE_DIRECTIVE:
        return 'Living Will';
      case DocumentType.POWER_OF_ATTORNEY:
        return 'Durable Power of Attorney';
      case DocumentType.TRUST:
        return 'Revocable Living Trust';
      case DocumentType.WILL:
        return 'Last Will & Testament';
      default:
        return '';
    }
  }
}

export class DocumentStatus {
  static PENDING = 'pending';
  static COMPLETE = 'complete';
}

export class ExcessChanges {
  static NAME = 'excess_name_changes';
  static ZIP_CODE = 'excess_zip_changes';
}

export class NotarizationStatus {
  static PREP = 'prep';
  static TECH_CHECK = 'tech_check';
  static ID_VERIFICATION = 'id_verification';
  static ID_VERIFICATION_SPOUSE = 'id_verification_spouse';
  static KBA = 'kba';
  static KBA_SPOUSE = 'kba_spouse';
  static SIGNATURE_CREATION = 'signature_creation';
  static SIGNATURE_CREATION_SPOUSE = 'signature_creation_spouse';
  static WAITING_TO_START = 'waiting_to_start';
  static ONGOING = 'ongoing';
  static FAILED = 'failed';
  static COMPLETED = 'completed';
  static ABANDONED = 'abandoned';

  static FINISHED_STATUSES = [
    NotarizationStatus.FAILED,
    NotarizationStatus.COMPLETED,
    NotarizationStatus.ABANDONED,
  ];
}

export class NotarizationPrepLocation {
  static REVIEW_REQS = 'review_reqs';
  static SIGN_PROC = 'sign_proc';
  static ABOUT_NOTARY = 'about_notary';
  static DOC_REVIEW = 'doc_review';
}

export class AppLocation {
  static HOME = 'home';
  static ACCOUNT = 'account';
  static ABOUT_YOU = 'about_you';
  static FAMILY = 'family';
  static REAL_ESTATE = 'real_estate';
  static GUARDIANS = 'guardians';
  static ASSET_BENEFICIARIES = 'asset_beneficiaries';
  static EXECUTORS = 'executors';
  static HEALTHCARE = 'healthcare';
  static DOCUMENTS = 'documents';
  static FAMILY_CENTER = 'family_center';

  static PLAN_LOCATIONS = [
    AppLocation.ABOUT_YOU,
    AppLocation.FAMILY,
    AppLocation.REAL_ESTATE,
    AppLocation.GUARDIANS,
    AppLocation.ASSET_BENEFICIARIES,
    AppLocation.EXECUTORS,
    AppLocation.HEALTHCARE,
  ];
}

// These values must match the values in the backend PersonRole enum
export class PersonRole {
  static BENEFICIARY = 'beneficiary';
  static EXECUTOR = 'executor';
  static HEALTHCARE_AGENT = 'healthcare_agent';
  static GUARDIAN = 'guardian';
  static CHILD = 'child';
  static SELF = 'self';
  static SPOUSE = 'spouse';
}

export class SectionType {
  static CLIENT = 'client';
  static SPOUSE = 'spouse';
  static JOINT = 'joint';
}
