import React from 'react';
import PropTypes from 'prop-types';

import { Tip, Tips } from '@c/tips';
import BequestStep from './BequestStep';
import { ProgressSections } from '@c/progress';
import { ShareDistribution } from 'models/client-data/enums';
import renderWuiSaveButton from '@c/layout/renderWuiSaveButton';
import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';
import { AlternateBeneficiary, PrimaryBeneficiary } from 'models/client-data/assets';
import { AppLocation } from 'utils/enums';

import Bequest from './Bequest';
import Fab from '@wui/input/fab';
import WuiTheme from '@willing-shared/WuiTheme';
import Layout from '@c/layout/Layout';
import Spacer from '@wui/layout/spacer';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import TabDivider from '@wui/layout/tabDivider';
import Typography from '@wui/basics/typography';
import ScreenReaderNotification from '@c/ScreenReaderNotification';

export default class BequestInterface extends ClientDataReferer {
  static baseUrl = '';

  static contextTypes = {
    section: PropTypes.object,
    ...ClientDataReferer.contextTypes,
  };

  constructor(...args) {
    super(...args);

    // When loading the interface, initialize each bequest with the
    //   current step it should be on.
    const initialSteps = this.visibleAssets.reduce((result, a) => {
      result[a.assetUUID] = BequestStep.fromAsset(a, this.testator);
      return result;
    }, {});

    // Additionally, we check to see if any real estate assets must
    //   be broken out. This can happen when switching between the
    //   old and new bequest interface.
    Object.assign(
      initialSteps,
      this.clientDataHolder.updateClientData(this.updateRealEstateAssets),
    );

    // Each bequest stores its step in the interface instead of
    //   internally so that it is easy to determine the state of
    //   all bequests.
    this.state = { steps: initialSteps, assetJustDeleted: false };
  }

  updateRealEstateAssets = () => {
    const residualBeneficiaries = this.testator.getPrimaryBeneficiariesForAsset(
      this.testator.residualAsset,
    );
    const residualJointBeneficiaries = this.testator.getPrimaryBeneficiariesForAsset(
      this.testator.residualJointAsset,
    );

    const soleResidual = residualBeneficiaries.length === 1 ? residualBeneficiaries[0] : {};

    const steps = {};

    // None of this matters if the user is not married.
    if (!this.testator.isMarried) {
      return steps;
    }

    this.testator.ownAndJointRealEstates.forEach(r => {
      const existingPrimaries = this.testator.getPrimaryBeneficiariesForAsset(r);
      const soleExistingPrimary = existingPrimaries[0];

      const addSpouseAndResiduals =
        // If there are primaries set for the asset
        //   already, then it is either the spouse
        //   from this version of the interface, or
        //   the future alternates, which will be
        //   converted below.
        existingPrimaries.length === 0 &&
        // This only happens when the residual asset has
        //   beneficiaries, because we need to copy them
        //   to the spouse. If there are no beneficiaries
        //   set for the residual yet, then selecting one
        //   that is not the spouse would automatically
        //   breakout the asset.
        residualBeneficiaries.length !== 0 &&
        // If the only residual beneficiary is the spouse,
        //   then there is no need to breakout the asset.
        soleResidual.personUUID !== this.testator.spouse.personUUID;

      const convertPrimariesToAlternates =
        // If there is more than one primary for this asset,
        //   then it must be from the old interface, and we
        //   need to convert them to alternates for the spouse.
        existingPrimaries.length > 1 ||
        // If there is only one beneficiary and it is not the
        //   spouse, it must be converted to an alternate for
        //   the spouse as well.
        (soleExistingPrimary && soleExistingPrimary.personUUID !== this.testator.spouse.personUUID);

      let sourceAsset = null;
      let sourcePrimaries = null;

      // A real estate asset was added after the residual beneficiaries
      //   were selected. Make the spouse the primary, and the residual
      //   beneficiaries the alternates.
      if (addSpouseAndResiduals) {
        sourceAsset = this.testator.residualAsset;
        sourcePrimaries = residualBeneficiaries;

        // The asset was created in the old interface, which assumes the
        //   spouse is the primary beneficiary. Move the existing primaries
        //   to be the alternates of the spouse.
      } else if (convertPrimariesToAlternates) {
        sourceAsset = r;
        sourcePrimaries = existingPrimaries;

        // There are real estate assets without any explicit beneficiaries
        //   that need the primaries from the residual joint asset to be
        //   the alternates of the spouse.
      } else if (residualJointBeneficiaries.length) {
        sourceAsset = this.testator.residualJointAsset;
        sourcePrimaries = residualJointBeneficiaries;
      }

      if (sourceAsset) {
        const spouseBeneficiary = this.testator.spouse.basePerson(true);
        spouseBeneficiary.owner = this.testator.ownerString;
        spouseBeneficiary.shareDistribution = sourceAsset[this.testator.assetShareDistributionKey];

        spouseBeneficiary.alternateBeneficiaries = sourcePrimaries.map(b => {
          const alternate = b.basePerson(true);
          alternate.sharePercentage = b.sharePercentage;

          return new AlternateBeneficiary(alternate);
        });

        // Remove any existing beneficiariesfrom the asset for
        //   the testator and then apply the new ones.
        this.testator.removeBequestsFromAsset(r);
        r.primaryBeneficiaries.push(new PrimaryBeneficiary(spouseBeneficiary));

        // Since the spouse is the only beneficiary, the
        //   share distribution should be set to equal
        //   shares.
        r.setRawValue(this.testator.assetShareDistributionKey, ShareDistribution.EQUAL_SHARES);

        steps[r.assetUUID] = BequestStep.REVIEW;
      }
    });

    this.testator.removeBequestsFromAsset(this.testator.residualJointAsset);

    return steps;
  };

  get visibleAssets() {
    return this.testator.assetsVisibleForBequests;
  }

  reviewStepCount(steps) {
    return Object.values(steps).filter(s => s === BequestStep.REVIEW).length;
  }

  updateStep = (asset, step) => {
    let shouldPersist = false;

    this.clientDataHolder.updateClientData(() => {
      const { steps } = this.state;
      const previousStep = steps[asset.assetUUID];
      const previouslyReviewed = this.reviewStepCount(steps);
      const visibleAssetUUIDs = this.visibleAssets.map(a => a.assetUUID);

      // Set the step for the current asset.
      steps[asset.assetUUID] = step;

      // Remove any steps from the list if the asset
      //   is no longer visible.
      Object.keys(steps)
        .filter(k => !visibleAssetUUIDs.includes(k))
        .forEach(k => delete steps[k]);

      // The asset has been deleted if it is no longer in the list
      //   of steps.
      const assetJustDeleted = !Object.keys(steps).includes(asset.assetUUID);

      // Only persist when the number of reviewed assets changes
      //   and we are either deleting an asset or changing the step
      //   for the asset.
      shouldPersist =
        previouslyReviewed !== this.reviewStepCount(steps) &&
        (previousStep !== BequestStep.REVIEW || assetJustDeleted);

      this.setState({ steps, assetJustDeleted });
    });

    if (shouldPersist) {
      this.persist();
    }
  };

  async persist() {
    const {
      section: { nextPending, persist },
    } = this.context;

    // Prevent double persistence. Shouldn't really be possible,
    //   but can't hurt as a safeguard.
    if (nextPending) {
      return;
    }

    // Persist the valid data and don't trigger validations
    //   because we do that as part of the interface already.
    await persist(false);
  }

  addSpecificGift = () => {
    this.updateStep(this.testator.addAsset(''), BequestStep.DEFINE_ASSET);
  };

  renderHeader() {
    const nameForTitle = this.clientData.isPlanningForSpouse ? this.testator.firstNames : '';

    return (
      <React.Fragment>
        <Typography variant="h3" role="heading" aria-level="1">
          {nameForTitle} Assets
        </Typography>

        <Typography>Choose how would you like to leave your assets.</Typography>

        <TabDivider dashed halfMargin />
      </React.Fragment>
    );
  }

  renderAddSpecificGift() {
    const {
      section: { nextPending },
    } = this.context;

    return (
      <React.Fragment>
        {this.visibleAssets.length > 1 && <Spacer v={30} />}

        <Grid item align="right">
          <Fab
            icon={AddIcon}
            iconAlign="right"
            disabled={nextPending}
            label="Add a Specific Gift"
            onClick={this.addSpecificGift}
            className="bequest-interface-add"
          />
        </Grid>

        <Spacer v={30} />
      </React.Fragment>
    );
  }

  renderBequest = asset => {
    const { steps } = this.state;
    const step = steps[asset.assetUUID];

    return (
      <Bequest
        step={step}
        asset={asset}
        key={asset.assetUUID}
        updateStep={this.updateStep}
        visibleAssets={this.visibleAssets}
      />
    );
  };

  saveDisabled(nextPending) {
    if (nextPending) {
      return true;
    }

    return !this.visibleAssets.every(a => a.isCompleteForBequest(this.testator));
  }

  renderSaveButton = (buttonProps, state) => {
    const disabled = this.saveDisabled(state.nextPending);
    const tooltip =
      disabled && !state.nextPending ? 'Please ensure all values are filled in above.' : null;
    const props = { tooltip, ...buttonProps, disabled };

    return renderWuiSaveButton(props, state);
  };

  render() {
    const { assetJustDeleted } = this.state;

    return (
      <Layout
        wide
        padded={false}
        testatorSwitcher
        nextValidatesWhenDisabled={false}
        buttonRenderer={this.renderSaveButton}
        progressSection={ProgressSections.YOUR_WISHES}
        progressPage={ProgressSections.YOUR_WISHES.ASSETS}
        appLocation={AppLocation.ASSET_BENEFICIARIES}
        showBackButton
      >
        <Tips>
          <Tip title="Leaving Assets">
            This page provides a single place where you can manage your asset distribution plan. The
            program takes these wishes and turns them into legally binding language in your will.
          </Tip>
          <Tip title="Specific Gifts">
            An example of a specific gift is leaving a certain amount of cash, or a particular item,
            to a certain person.
            <br />
            <br />
            When it's time to distribute your assets, specific gifts are made, and then remaining
            assets are distributed according to the rest of your plan.
          </Tip>
        </Tips>

        <WuiTheme>
          <Spacer v={96} xsDown />
          <Spacer v={48} smUp />

          {assetJustDeleted && <ScreenReaderNotification>Asset deleted</ScreenReaderNotification>}

          {this.renderHeader()}
          {this.visibleAssets.slice(0, -1).map(this.renderBequest)}
          {this.renderAddSpecificGift()}
          {this.renderBequest(this.testator.residualAsset)}
        </WuiTheme>
      </Layout>
    );
  }
}
