import React from 'react';
import Script from 'react-load-script';
import { isMobile } from 'react-device-detect';
import WizardSection from 'ui/components/wizard/WizardSection';
import { Urls } from 'urls';
import DigexPrepIntro from 'ui/sections/notarize/pages/prep/DigexPrepIntro';
import DigexPrepStart from 'ui/sections/notarize/pages/prep/DigexPrepStart';
import DigexPrepReviewReqs from 'ui/sections/notarize/pages/prep/DigexPrepReviewReqs';
import DigexPrepSigningProcedure from 'ui/sections/notarize/pages/prep/DigexPrepSigningProcedure';
import DigexPrepAboutNotary from 'ui/sections/notarize/pages/prep/DigexPrepAboutNotary';
import DigexPrepDocReview from 'ui/sections/notarize/pages/prep/DigexPrepDocReview';
import WaitingRoom from 'ui/sections/notarize/pages/WaitingRoom';
import CallRoom from 'ui/sections/notarize/pages/CallRoom';
import TechCheckVideoSource from 'material-ui/sections/notarization/TechCheckVideoSource';
import IdentityVerification from 'ui/sections/notarize/pages/IdentityVerification';
import SpouseIdentityVerification from 'ui/sections/notarize/pages/SpouseIdentityVerification';
import KBAQuestions from 'ui/sections/notarize/pages/KBAQuestions';
import SpouseKBAQuestions from 'ui/sections/notarize/pages/SpouseKBAQuestions';
import TechCheckAudioSource from 'material-ui/sections/notarization/TechCheckAudioSource';
import TechCheckAudioSink from 'material-ui/sections/notarization/TechCheckAudioSink';
import IdVerification from 'ui/sections/notarize/pages/id-verification/IdVerification';
import SpouseIdVerification from 'ui/sections/notarize/pages/SpouseIdVerification';
import SignatureSelection from 'ui/sections/notarize/pages/SignatureSelection';
import DrawSignature from 'ui/sections/notarize/pages/DrawSignature';
import SpouseSignatureSelection from 'ui/sections/notarize/pages/SpouseSignatureSelection';
import TechCheckBrowserDevices from 'material-ui/sections/notarization/TechCheckBrowserDevices';
import TechCheckInternet from 'material-ui/sections/notarization/TechCheckInternet';
import FinalStatus from 'ui/sections/notarize/pages/FinalStatus';
import IdIntro from 'ui/sections/notarize/pages/IdIntro';
import SpouseIdIntro from 'ui/sections/notarize/pages/SpouseIdIntro';
import SpouseDrawSignature from 'ui/sections/notarize/pages/SpouseDrawSignature';
import { multipleChoiceSignatureStates } from 'utils';

export default class SectionNotarize extends WizardSection {
  static url = Urls.notarize;
  static title = 'Notarize';
  static description = 'Notarize your documents';
  static finalUrl = Urls.storage;
  static fallbackUrl = Urls.notarize;
  static showFooter = false;

  static getPageFlow(clientData, serverData, experimentContext, visit) {
    // visit(DigexIntro);
    visit(DigexPrepIntro);
    visit(DigexPrepStart);
    visit(DigexPrepReviewReqs);
    visit(DigexPrepSigningProcedure);
    visit(DigexPrepAboutNotary);
    visit(DigexPrepDocReview);
    visit(TechCheckBrowserDevices);
    visit(TechCheckInternet);
    if (!isMobile) {
      visit(TechCheckVideoSource);
      visit(TechCheckAudioSink);
      visit(TechCheckAudioSource);
    }
    visit(IdIntro);
    visit(IdVerification);
    visit(IdentityVerification);
    visit(KBAQuestions);

    // Only Nevada users get to choose their signature, everyone else must draw it
    if (multipleChoiceSignatureStates.includes(clientData.verificationAddress.state)) {
      visit(SignatureSelection);
    } else {
      visit(DrawSignature);
    }

    if (clientData.spouseSigns(serverData.documentBundle)) {
      visit(SpouseIdIntro);
      visit(SpouseIdVerification);
      visit(SpouseIdentityVerification);
      visit(SpouseKBAQuestions);

      if (multipleChoiceSignatureStates.includes(clientData.spouse.verificationAddress.state)) {
        visit(SpouseSignatureSelection);
      } else {
        visit(SpouseDrawSignature);
      }
    }
    visit(WaitingRoom);
    visit(CallRoom);
    visit(FinalStatus);
  }

  mitekLoaded = new Promise((resolve, reject) => {
    this.mitekLoadSuccess = resolve;
    this.mitekLoadError = reject;
  });

  getSectionContext() {
    return {
      mitekLoaded: this.mitekLoaded,
      ...super.getSectionContext(),
    };
  }

  render() {
    return (
      <>
        <Script
          url={window.mitekWebWorkerSrc}
          onLoad={this.mitekLoadSuccess}
          onError={this.mitekLoadError}
        />
        {super.render()}
      </>
    );
  }
}
