import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Link as RouterLink } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';

import CustomIcon from '@wui/basics/customIcon';
import Panel from '@wui/layout/panel';
import Grid from '@wui/layout/grid';
import Typography from '@wui/basics/typography';
import Button from '@wui/input/button';
import Link from '@wui/basics/link';

import { useGlobalContext } from 'hooks';

import { ReactComponent as PendingIcon } from '@a/images/pending.svg';
import { ReactComponent as GreenTickIcon } from '@a/images/green-tick.svg';
import SectionDocuments from 'ui/sections/documents/SectionDocuments';
import SectionSign from 'ui/sections/sign/SectionSign';

const useStyles = makeStyles(theme => ({
  statusIcon: {
    position: 'relative',
    top: 6,
    height: 20,
    width: 20,

    [theme.breakpoints.phone]: {
      top: 4,
      width: 16,
      height: 16,
    },
  },
}));

const SectionEntry = ({ section, special }) => {
  const { clientData, serverData, firstUrlForSection } = useGlobalContext();

  const classes = useStyles();

  const complete = clientData.isSectionValid(section);
  const inProgress = clientData.isSectionInProgress(section);

  const status = useMemo(() => {
    if (complete) {
      return 'Complete';
    } else if (inProgress) {
      return 'In Progress';
    } else {
      return 'Not Started';
    }
  }, [complete, inProgress]);

  const statusIcon = useMemo(() => {
    const red = theme => theme.palette.text.error;

    return (
      <CustomIcon
        src={complete ? GreenTickIcon : PendingIcon}
        className={classes.statusIcon}
        color={inProgress ? red : null}
      />
    );
  }, [complete, inProgress, classes]);

  const url =
    serverData.fromPortunus &&
    !serverData.hasNotarizedOnline &&
    section.prototype instanceof SectionSign
      ? SectionDocuments.reviewUrl
      : firstUrlForSection(section);

  return (
    <Panel lessPadding tableRow special={special}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        alignContent="center"
        spacing={1}
      >
        <Grid item xs={8} sm={6}>
          <Grid container direction="row">
            <Hidden smUp>
              <Grid item xs={2}>
                {statusIcon}
              </Grid>
            </Hidden>
            <Grid item xs={10} sm={12}>
              <Typography variant="h6">{section.title}</Typography>
            </Grid>
          </Grid>

          <Hidden only="xs">
            <Typography>{section.description}</Typography>
          </Hidden>
        </Grid>

        <Hidden only="xs">
          <Grid item sm={3}>
            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid item>{statusIcon}</Grid>

              <Grid item>
                <Typography variant="body1">{status}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>

        <Grid item xs={4} sm={3} align="center">
          {complete ? (
            <Link to={url} variant="body2" component={RouterLink} tabIndex="0">
              {section.dashboardEditText}
            </Link>
          ) : (
            <Link to={url} underline="none" component={RouterLink} tabIndex="0">
              <Button
                variant={special ? 'contained' : 'outlined'}
                color={special ? 'primary' : 'initial'}
                noMinWidth
              >
                {inProgress ? 'Resume' : 'Start'}
              </Button>
            </Link>
          )}
        </Grid>
      </Grid>
    </Panel>
  );
};

SectionEntry.propTypes = {
  section: PropTypes.func.isRequired,

  // Used for panel status and button styles
  special: PropTypes.bool,
};

SectionEntry.defaultProps = {
  special: false,
};

export default observer(SectionEntry);
