import React from 'react';
import PropTypes from 'prop-types';

import { WBoolean } from '!m/types';
import FieldGroup from '../FieldGroup';
import { Lineage } from '!m/client-data/enums';
import BoundNameField from '@c/BoundNameField';
import BoundTextField from '@c/BoundTextField';
import BoundRadioGroup from '@c/BoundRadioGroup';
import ListItemSideTitle from '../ListItemSideTitle';
import { capitalize } from '@willing-shared/utils/text';
import { Child as ChildModel } from '!m/client-data/main';
import { runTypeValidation, validatePersonNameUniqueness } from '!m/utils';
import { StaticValue } from 'ui/components/StaticValue';
import withHandleNameConflicts from 'hocs/withHandleNameConflicts';

class Child extends React.Component {
  static propTypes = {
    child: PropTypes.object,
    disabled: PropTypes.bool.isRequired,
    handleNameConflicts: PropTypes.func.isRequired,
  };

  static contextTypes = {
    testator: PropTypes.object,
    clientDataHolder: PropTypes.object,
  };

  nameValidations() {
    return [validatePersonNameUniqueness(this.context.clientDataHolder.clientData, this.child)];
  }

  newChild = new ChildModel();

  // If a child is supplied, then we should modify that.
  //   Otherwise, we modify a child that is not actually
  //   stored for future use.
  get child() {
    return this.props.child || this.newChild;
  }

  remove() {
    this.context.testator.children.splice(
      this.context.testator.children.findIndex(c => c === this.child),
      1,
    );
  }

  get sideTitle() {
    return (
      <ListItemSideTitle
        item={this.child}
        onDelete={this.remove.bind(this)}
        list={this.context.testator.children}
      />
    );
  }

  get lineageOptions() {
    const testatorName = this.context.testator.firstName || 'You';
    const spouseName = this.context.testator.spouse.firstName || 'your spouse';

    return {
      [Lineage.BOTH]: `${testatorName} and ${spouseName}`,
      [Lineage.CLIENT]: `${testatorName} and someone else`,
      [Lineage.SPOUSE]: `${capitalize(spouseName)} and someone else`,
    };
  }

  get showLineage() {
    // If the user is not married, no need
    //   to show this field.
    if (!this.context.testator.isMarried) {
      return false;

      // Don't change the display until the previous
      //   section is filled out.
    } else if (this.props.disabled) {
      return true;
    }

    return (
      this.context.testator.bothTestatorsAreParents &&
      !this.context.testator.childrenHaveSameParents
    );
  }

  renderLineage() {
    if (!this.showLineage) {
      // set the child's lineage based on which testator is the parent
      let lineage = Lineage.CLIENT;
      if (!this.context.testator.isMarried) {
        lineage = Lineage.CLIENT;
      } else if (this.context.testator.childrenHaveSameParents) {
        lineage = Lineage.BOTH;
      } else if (!this.context.testator.bothTestatorsAreParents) {
        lineage = this.context.testator.isParent ? Lineage.CLIENT : Lineage.SPOUSE;
      }
      return <StaticValue obj={this.child} path="lineage" value={lineage} />;
    }

    return (
      <BoundTextField
        path="lineage"
        label="Child of"
        obj={this.child}
        options={this.lineageOptions}
      />
    );
  }

  get childName() {
    return this.child.firstName || 'this child';
  }

  get showMinor() {
    // Don't change the display until the previous
    //   section is filled out.
    if (this.props.disabled) {
      return true;
    }

    return this.context.testator.childrenCanBeMinors !== false;
  }

  get disableMinor() {
    // If it is not being shown, consider it enabled
    //   so this can be used in further field calculations.
    if (!this.showMinor) {
      return false;
    }

    // The field is disabled if lineage selection is visible
    //   but a value hasn't been selected yet. If lineage is
    //   not visible, then state is based on the name of the
    //   child being present or not.
    return (this.showLineage && !this.child.lineage) || !this.child.name;
  }

  renderMinor() {
    if (!this.showMinor) {
      return null;
    }

    const label = `Is ${this.childName} under 18 years old?`;
    const validations = [() => runTypeValidation(WBoolean, this.child.isMinor)];

    // `isMinor` is not an actual value passed to the
    //   backend, but a getter/setter. `raw` is false
    //   here so that we use the getter and setter.
    return (
      <BoundRadioGroup
        raw={false}
        label={label}
        path="isMinor"
        obj={this.child}
        disabled={this.disableMinor}
        skipStandardValidation={true}
        additionalValidations={validations}
      />
    );
  }

  // Whether or not to disable this field is based on
  //   the previous visible field being filled in.
  get disableExclude() {
    if (this.showMinor) {
      return this.child.isMinor === null;
    } else if (this.showLineage) {
      return !this.child.lineage;
    }

    return !this.child.name;
  }

  renderExclude() {
    const label = `Exclude ${this.childName}?`;

    return (
      <BoundRadioGroup
        label={label}
        obj={this.child}
        path="disinherited"
        disabled={this.disableExclude}
      />
    );
  }

  render() {
    const title = this.child.name || 'Add a Child';
    const { disabled, handleNameConflicts } = this.props;

    return (
      <FieldGroup
        level={2}
        variant="h4"
        title={title}
        sideTitle={this.sideTitle}
        disabled={disabled}
      >
        <BoundNameField
          obj={this.child}
          label="Child's Full Name"
          onBlur={handleNameConflicts(this.child)}
          additionalValidations={this.nameValidations()}
        />
        {this.renderLineage()}
        {this.renderMinor()}
        {this.renderExclude()}
      </FieldGroup>
    );
  }
}

export default withHandleNameConflicts(Child);
